var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('modal',{attrs:{"show":this.isInactive,"showClose":true,"type":"notice","gradient":"default","headerClasses":"justify-content-center","modalClose":function () {}}},[_c('div',{staticClass:"d-flex flex-row justify-content-center text-white",attrs:{"slot":"header"},slot:"header"},[_c('i',{staticClass:"fa fa-exclamation mr-4 display-3"}),_c('div',{staticClass:"d-flex flex-col justify-content-center align-content-center flex-wrap text-center"},[_c('div',{staticClass:"display-3"},[_vm._v("Session expiring in 5 minutes")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Due to user inactivity, the session will close in 5 minutes. ")])])])]),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"NIRA Kyc","title":"NIRA KYC"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-tv-2 text-primary',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Requests',
          icon: 'ni ni-send text-success',
          path: '/requests',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Reports (In Beta)',
          icon: 'ni ni-chart-bar-32 text-info',
          path: '/reports',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Billings',
          icon: 'fa fa-money-bill',
          path: '/billings/statements/topups',
        }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Payment Methods',
            path: '/billings/payments/methods',
          }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Top Ups', path: '/billings/statements/topups' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Expenditure',
            path: '/billings/statements/expenditure',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Settings',
          icon: 'ni ni-settings-gear-65 text-warning',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Keys', path: '/settings/keys' }}})],1),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"javascript:void(0)"},on:{"input":function($event){_vm.verified ? function () {} : _vm.toggleEnvironment}}},[_vm._v(" Switch to "+_vm._s(_vm.demoEnv ? "Live" : "Demo")+" "),_c('div',{staticStyle:{"margin-left":"20px"}}),_c('a',{attrs:{"href":"#switch_env"},on:{"click":this.toggleEnvironment}},[_c('base-switch',{attrs:{"checked":this.env,"value":this.env,"disabled":!this.verified}})],1)])])],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }