import Vue from 'vue'
import App from './App.vue'
import Flutterwave from 'flutterwave-vue-v3'
import router from './router'
import store from "./store";
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import axios from "axios";
import VueAxios from "vue-axios";
import IsDemo from "./plugins/isDemo"
import VueClipboards from 'vue-clipboards'
import VueCookies from 'vue-cookies';

import crypt from './plugins/cryptography';
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, onMessage } from 'firebase/messaging'
import { getFCMToken, requestPermission } from './plugins/firebase';
import NotificationsPlugin from './components/NotificationPlugin';

Vue.use(NotificationsPlugin)

const firebaseConfig = {
  apiKey: "AIzaSyB1Ow0_Pey_87X9dW7h9udGVcdV-xNMjoU",
  authDomain: "verify-kyc.firebaseapp.com",
  projectId: "verify-kyc",
  storageBucket: "verify-kyc.appspot.com",
  messagingSenderId: "543575398500",
  appId: "1:543575398500:web:9a0deedbf744f4626fe771",
  measurementId: "G-JF7YPYWXRK"
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseapp)
const messaging = getMessaging(firebaseapp)

requestPermission()

getFCMToken(messaging)

/// Handle incoming messages. Called when:
/// - a message is received while the app has focus
/// - the user clicks on an app notification created by a service worker
/// `messaging.onBackgroundMessage` handler. 
onMessage(messaging, (payload) => {
  console.log('Message received. ', payload)
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: '/index.jpeg',
    tag: payload.messageId
  };

  const notif = new Notification(
    notificationTitle,
    notificationOptions
  );

  notif.onclick = (event) => {
    alert(payload.notification.body)
  }

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      // The tab has become visible so clear the now-stale Notification.
      notif.close();
    }
  });
})

Vue.use(VueAxios, axios);

Vue.use(VueCookies);

Vue.use(Flutterwave, {});
// Vue.use(IsDemo);
Vue.use(VueClipboards)

Vue.use(crypt)

Vue.config.productionTip = false

Vue.use(ArgonDashboard)

const app = new Vue({
  router: router,
  store: store,
  el: "#app",
  render: h => h(App)
});

store.$app = app;
