<template>
  <div v-if="loading" class="dot_loading"></div>
</template>

<script>
export default {
  name: "loading-dots",
  props: {
    loading: {
      Boolean,
      default: false,
      description: "Indicates whether to show the loader or not",
    },
  },
};
</script>
<style></style>
