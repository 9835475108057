import axios from 'axios'
import Jsona from 'jsona'

const url = process.env.VUE_APP_API_BASE_URL
const jsona = new Jsona()

function env() {
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios.get(`${url}/account/environment`, options).then(response => {
        return {
            data: response.data.data,
        }
    });
}

function change(is_demo = false) {
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }

    const payload = JSON.stringify({ is_demo })

    return axios.post(`${url}/account/environment`, payload, options)
        .then(response => {
            return response.data
        })
}

function keys() {
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    }

    return axios.get(`${url}/account/api/generate`, options)
        .then(response => {
            console.log(response.data.data)
            return response.data.data;
        });
}

export default {
    change,
    env,
    keys
}
