import axios from "axios"
import Jsona from "jsona"

const url = process.env.VUE_APP_API_BASE_URL
const jsona = new Jsona()

function index(){
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios.get(`${url}/dashboard`, options).then(response => {
        return {
            data: response.data,
        }
    });
}

function update_counters(){
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios.get(`${url}/dashboard/counters`, options).then(response => {
        return {
            data: response.data.data,
        }
    });
}

function update_charts(){
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios.get(`${url}/dashboard/charts`, options).then(response => {
        return {
            data: response.data,
        }
    });
}

function balance()
{
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    return axios.get(`${url}/account/balance`, options).then(response => {
        return {
            data: response.data,
        }
    });
}

export default {
    index,
    update_counters,
    update_charts,
    balance
}
