import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import DocsLayout from '@/layout/DocsLayout'
// Example pages
import UserProfile from "@/views/Examples/UserProfile.vue";
import ListUserPage from "@/views/Examples/UserManagement/ListUserPage.vue";

const PasswordReset = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Reset.vue");
const PasswordEmail = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Email.vue");

Vue.use(Router)

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      meta: { middleware: auth },

      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue'),
          meta: { middleware: auth },
        },
        {
          path: '/requests',
          name: 'Requests',
          component: () => import(/* webpackChunkName: "requests" */ './views/Requests.vue'),
          meta: { middleware: auth },
        },
        {
          path: '/transactions',
          name: 'Transactions',
          component: () => import(/* webpackChunkName: "transactions" */ './views/Transactions.vue'),
          meta: { middleware: auth },
        },
        {
          path: '/verify/request',
          name: 'Request a Verification',
          component: () => import(/* webpackChunkName: "requests_verify" */ './views/VerifyRequest.vue'),
          meta: { middleware: auth },
        },
        {
          path: '/reports',
          name: 'Reports',
          component: () => import(/* webpackChunkName: "reports" */ './views/Reports.vue'),
          meta: { middleware: auth },
        },
        {
          path: 'billings/payments/methods',
          name: 'PaymentMethods',
          component: () => import(/* webpackChunkName: "billing_pay_methods" */ './views/PaymentMethods.vue'),
          meta: { middleware: auth },
        },
        {
          path: 'billings/statements/topups',
          name: 'TopUps',
          component: () => import(/* webpackChunkName: "billing_topups" */ './views/TopUps.vue'),
          meta: { middleware: auth },
        },
        {
          path: 'billings/statements/expenditure',
          name: 'Expenditure',
          component: () => import(/* webpackChunkName: "billing_expenditure" */ './views/Expenditures.vue'),
          meta: { middleware: auth },
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "profile" */ './views/UserProfile.vue'),
          meta: { middleware: auth },
        },
        {
          path: '/settings/keys',
          name: 'API Keys',
          component: () => import(/* webpackChunkName: "settings" */ './views/Settings/APIKeys.vue'),
          meta: { middleware: auth },
        },
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
          meta: { middleware: guest }
        },
        {
          path: '/register',
          name: 'register',
          component: () => import(/* webpackChunkName: "login_register" */ './views/Register.vue'),
          meta: { middleware: guest }
        },
        {
          path: "/password/reset",
          name: "PasswordReset",
          component: PasswordReset,
          meta: { middleware: guest }
        },
        {
          path: "/password/email",
          name: "PasswordEmail",
          component: PasswordEmail,
          meta: { middleware: guest }
        },

      ]
    },
    {
      path: '/documentation',
      redirect: 'login',
      component: DocsLayout,
      meta: { middleware: auth },
      children: [
        {
          path: '/',
          name: "API Documentation",
          component: () => import(/* webpackChunkName: "documentation_index" */ "@/views/Documentation.vue")
        },
        {
          path: '/refs/verifykyc',
          name: 'Verify KYC Api Reference',
          component: () => import(/* webpackChunkName: "documentation_reference" */ "@/views/Documentation/VerifyKYC.vue"),
        }
      ]
    }
    // {
    //   path: "/examples",
    //   component: DashboardLayout,
    //   name: "Examples",
    //   children: [
    //     {
    //       path: "user-profile",
    //       name: "User Profile",
    //       components: { default: UserProfile },
    //       meta: { middleware: auth }
    //     },
    //   {
    //     path: "user-management/list-users",
    //     name: "List Users",
    //     components: { default: ListUserPage },
    //     meta: { middleware: auth }
    //   }
    // ]
    // },
  ]
});
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware)
    return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
    const context = { from, next, to, router };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
