import service from '@/store/services/request-service';

const state = {
    data: []
}

const mutations = {
    SET_REQUESTS: (state, req) => {
        state.data = req
    },
}

const actions = {
    requests({ commit, dispatch }, params) {
        return service.requests(params)
            .then((req) => {
                commit('SET_REQUESTS', req);
            })
    },
}

const getters = {
    requestsTable: state =>state.data
}

const requests = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default requests