<template>
  <span class="header__balance show-for-medium " :class="classes" title="Click to view number of requests"
    style="cursor: pointer" @click="toggleRequests">
    <span>
      <span class="balance_load_color">
        Requests this Month:
        <loading-dots :loading="loading.balance" />
        <span>
          {{ loading.balance ? null : display_requests + " Requests" }}
        </span>
      </span>
    </span>
  </span>
</template>
<script>
import LoadingDots from "./LoadingDots.vue";
export default {
  name: "balance-component",
  components: { LoadingDots },
  data() {
    return {
      showCurrency: this.$cookies.get("showCurrency") === "true",
    }
  },
  props: {
    loading: {
      type: Object,
      default: () => ({
        balance: false,
      }),
    },
    balance: {
      type: Number,
      default: 0,
    },
    requests: {
      type: Number,
      default: 0,
    },
    classes: {
      type: [String, Object, Array],
      default: "",
    },
  },
  computed: {
    display_balance() {
      return this.balance
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    display_requests() {
      return this.requests;
    },
    bal() {
      return (this.balance / 30).toFixed(0) ?? 0;
    },
    getShowCurrency() {
      const cookieValue = this.$cookies.get("showCurrency");
      if (cookieValue == null) {
        return true;
      } else return cookieValue;
    }
  },
  methods: {
    toggleRequests() {
      console.log(this.$cookies.get("showCurrency"));
      const cookieValue = this.$cookies.get("showCurrency");
      this.showCurrency = !this.showCurrency;
      this.$cookies.set('showCurrency', this.showCurrency, "31d")
    }
  }
};
</script>
