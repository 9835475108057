import service from '../services/verification-service'

const state = {
    verifyResponse: [],
    batchVerifyResponse: [],
    verifyErrors: [],
}

const mutations = {
    SET_VER_RESPONSE: (state, res) => {
        state.verifyResponse = res
    },
    SET_ERRORS: (state, errors) => {
        state.verifyErrors = errors
    },
    SET_BATCH_VER_RESPONSE: (state, res) => {
        state.batchVerifyResponse = res
    }
}

const actions = {
    verify({commit, dispatch}, params) {
        return service.verify(params)
        .then(res => {
            commit("SET_VER_RESPONSE", res)
        })
    },
    batchVerify({commit, dispatch}, params) {
        return service.batchVerify(params)
        .then(res => {
            commit("SET_BATCH_VER_RESPONSE", res.data)
        })
    }
}

const getters = {
    verifyResponse: state => state.verifyResponse,
    batchVerifyResponse: state => state.batchVerifyResponse,
}

const verify = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default verify;
