import CryptoJS from "crypto-js";
import csv from "jquery-csv";

const crypt = {
    install: (Vue) => {
        // Encrypts a string using the AES algorithm.
        Vue.encrypt = (key, iv, data) => {

            const salt = CryptoJS.lib.WordArray.random(128 / 8);

            const i = CryptoJS.enc.Utf8.parse(iv);

            var k = CryptoJS.PBKDF2(key, salt, {
                hasher: CryptoJS.algo.SHA512,
                keySize: 256 / 32,
                iterations: 999,
                });

            const json_data = JSON.stringify(data);

            var encrypted = CryptoJS.AES.encrypt(json_data, k, {
                iv: i,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });

            return window.btoa(
                JSON.stringify({
                    ct: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
                    salt: CryptoJS.enc.Hex.stringify(salt),
                })
            );
        }
        Vue.decrypt = (key, iv, data) => {
            var encrypted = data.ct;

            var salt = CryptoJS.enc.Hex.parse(data.salt);

            const i = CryptoJS.enc.Utf8.parse(iv);

            var k = CryptoJS.PBKDF2(key, salt, {
                hasher: CryptoJS.algo.SHA512,
                keySize: 256 / 32,
                iterations: 999,
            });

            var decrypted = CryptoJS.AES.decrypt(encrypted, k, {
                iv: i,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7,
            });

            return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        }
        Vue.parseCSVFile = async (csvfile) => {
            let finalData = {}
            let a = await new Promise((resolve, reject) => {
                var reader = new FileReader();
                reader.readAsText(csvfile);
                reader.onload = async function (event) {
                    var cscv = await event.target.result;
                    var data = csv.toArrays(cscv);
                    finalData['num'] = data.length - 1
                    finalData['records'] = []
                    let headers = []

                    for (let index = 0; index < data.length; index++) {
                        const row = data[index];
                        if (index == 0) {
                            headers = row.splice(0, 6)
                            if(headers.includes('nin')) {
                                continue
                            } else {
                                reject('Invalid file, please use the downloaded template')
                            }
                        }
                        else {
                            let record = {}
                            for (let i = 0; i < headers.length; i++) {
                                const header = headers[i];
                                record[header] = row[i]
                            }
                            finalData['records'].push(record)
                        }
                    }
                    resolve(headers)
                }
            })

            return finalData
        }
    },
}

export default crypt
