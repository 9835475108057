import service from '../services/environment-service'

const state = {
    isDemo: false,
    keys: {
        secret: null,
        iv: null,
        apiKey: null,
    }
}

const mutations = {
    SET_ENV: (state, env) => {
        state.isDemo = env
    },
    SET_KEYS: (state, env) => {
        state.keys = env
    }
}

const actions = {
    change({ commit, dispatch }, params) {
        return service.change(params)
            .then((env) => {
                commit('SET_ENV', env.environment == 'live' ? false : true)
                commit('SET_KEYS', {
                    secret: env.keys.secret_key,
                    iv: env.keys.public_key,
                    apiKey: env.keys.api_key
                })
            })
    },
    getEnv({ commit, dispatch }, params) {
        return service.env(params)
            .then((response) => {
                commit('SET_ENV', response.data.env == 'live' ? false : true)
                commit('SET_KEYS', {
                    secret: response.data.keys.secret_key,
                    iv: response.data.keys.public_key,
                    apiKey: response.data.keys.api_key
                })
            })
    },
    getNewKeys({ commit, dispatch }, params) {
        return service.keys(params)
            .then((response) => {
                commit('SET_KEYS', {
                    secret: response.new_keys.secret_key,
                    iv: response.new_keys.public_key,
                    apiKey: response.new_keys.api_key
                })
            })
            .catch((error) => {
                this.$notify({
                    type: "danger",
                    message: "An error occured!",
                });
            })
    }
}

const getters = {
    isDemo: state => state.env,
    keys: state => state.keys
}

const account = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default account
