import service from '../services/expenditure-service'

const state = {
    data: [],
}

const mutations = {
    SET_DATA: (state, res) => {
        state.data = res
    }
}

const actions = {
    index({commit, dispatch}, params) {
        return service.index(params)
        .then(res => {
            commit('SET_DATA', res.data)
        })
    }
}

const getters = {
    data: state => state.data,
}

const expenditure = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default expenditure
