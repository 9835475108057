<template>
  <div
    class="form-group"
    :class="[
      { 'input-group': hasIcon },
      { 'has-danger': error },
      { focused: focused },
      { 'has-label': label || $slots.label },
      { 'has-success': valid === true },
      { 'has-danger': valid === false },
    ]"
  >
    <slot name="label">
      <label v-if="label" class="form-control-label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
          <span v-if="addonLeftText" class="mx-2">
            {{ addonLeftText }}
          </span>
        </slot>
      </span>
    </div>
    <slot v-bind="slotData">
      <input
        :value="value"
        v-on="listeners"
        v-bind="$attrs"
        class="form-control px-3"
        :class="[
          { 'is-valid': valid === true },
          { 'is-invalid': valid === false },
          inputClasses,
        ]"
        :style="inputStyles"
        aria-describedby="addon-right addon-left"
      />
      <div class="copykey d-flex align-items-center px-2">
        <i class="fa fa-check " :class="this.copied ? '' : 'hide'"></i><span class="copy-small " :class="this.copied ? '' : 'hide'">copied!</span>
      </div>
    </slot>
    <div
      v-if="(addonRightIcon || $slots.addonRight) && !copy"
      class="input-group-append"
    >
      <span class="input-group-text" :style="copy ? 'cursor: pointer;' : ''">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
          <span v-if="addonRightText" class="ml-2" :style="addonRightTextStyle">
            {{ addonRightText }}
          </span>
        </slot>
      </span>
    </div>
    <div v-if="copy" class="input-group-append">
      <span
        class="input-group-text"
        :style="copy ? 'cursor: pointer;' : ''"
        v-clipboard="value"
        @click="onCopy"
      >
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
          <span v-if="addonRightText" class="ml-2">
            {{ addonRightText }}
          </span>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
        v-if="error"
      >
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: "base-input",
  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    valid: {
      type: Boolean,
      description: "Whether is valid",
      default: undefined,
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    addonRightIcon: {
      type: String,
      description: "Addon right icon",
    },
    addonRightText: {
      type: String,
      description: "Addon right text",
    },
    addonRightTextStyle: {
      type: String,
      description: "Addon right text style",
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon",
    },
    addonLeftText: {
      type: String,
      description: "Addon left text",
    },
    copy: {
      type: Boolean,
      default: false,
    },
    inputStyles: {
      type: String,
      description: "",
    },
  },
  data() {
    return {
      focused: false,
      copied: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },
  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
    onCopy() {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 3000);
    },
  },
};
</script>
<style>
.copykey {
  position: absolute;
  right: 7px;
  top: -24px;
  cursor: pointer;
  background-color: #e9ecef;
  border-radius: 7px 7px 0 0;
}
.copy-small {
  padding-left: 10px;
  padding-right: 5px;
  background-color: #eef0fc;
  font-weight: 500;
}
.hide {
  display: none !important;
}
</style>
