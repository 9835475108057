<template>
  <div id="app">
    <notifications></notifications>
    <router-view />
  </div>
</template>

<script>

export default {
};
</script>
