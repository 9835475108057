<template>
  <div>
    <nav
      class="navbar"
      :class="[
        { 'navbar-expand-md': expand },
        { 'navbar-transparent': transparent },
        { [`bg-${type}`]: type },
      ]"
    >
      <div :class="containerClasses">
        <slot name="brand">
          <!-- <router-link
          :to="$route.path"
          class="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
        >
          {{ $route.name }}
        </router-link> -->
        </slot>
        <slot name="account_balance">
          <balance-component
            :classes="`d-none d-md-block`"
            :balance="balance"
            :requests="requests"
            :loading="loading"
          ></balance-component>
        </slot>
        <navbar-toggle-button
          v-if="showToggleButton"
          :toggled="toggled"
          :target="contentId"
          @click.native.stop="toggled = !toggled"
        >
          <span class="navbar-toggler-icon"></span>
        </navbar-toggle-button>

        <div
          class="collapse navbar-collapse"
          :class="{ show: toggled }"
          :id="contentId"
          v-click-outside="closeMenu"
        >
          <slot :close-menu="closeMenu"></slot>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import NavbarToggleButton from "./NavbarToggleButton";
import BalanceComponent from "./BalanceComponent.vue";

export default {
  name: "base-nav",
  components: {
    NavbarToggleButton,
    BalanceComponent,
  },
  props: {
    type: {
      type: String,
      default: "",
      description: "Navbar type (e.g default, primary etc)",
    },
    title: {
      type: String,
      default: "",
      description: "Title of navbar",
    },
    contentId: {
      type: [String, Number],
      default: Math.random().toString(),
      description:
        "Explicit id for the menu. By default it's a generated random number",
    },
    containerClasses: {
      type: [String, Object, Array],
      default: "container-fluid",
    },
    transparent: {
      type: Boolean,
      default: false,
      description: "Whether navbar is transparent",
    },
    expand: {
      type: Boolean,
      default: false,
      description: "Whether navbar should contain `navbar-expand-lg` class",
    },
    showToggleButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      toggled: false,
      loading: {
        balance: false,
      },
      balance: 0,
      requests: 0,
      z: 0,
    };
  },
  methods: {
    closeMenu() {
      this.toggled = false;
    },
  },
  async created() {
    this.unwatch = this.$store.watch(
      (s, g) => g["dashboard/balance"],
      (n, o) => {
        if (n !== o) {
          this.balance = n;
        }
      }
    );
    this.unwatch_requests = this.$store.watch(
      (s, g) => g["dashboard/requests"],
      (n, o) => {
        if (n !== o) {
          this.requests = n;
        }
      }
    );
    await this.$store.dispatch("dashboard/balance");
  },
  async mounted() {
    let i = setInterval(async () => {
      if (!this.loading.balance && this.$store.state.profile.me != null) {
        this.loading.balance = true;
        await this.$store.dispatch("dashboard/balance");
        this.loading.balance = false;
      }
    }, 1000 * 10);
  },
  async unmounted() {}
};
</script>
<style></style>
