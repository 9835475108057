<template>
  <div class="mt-3 mx-3">
    <div class="d-flex justify-content-between flex-row">
      <div class="col-md-3 col-sm-6 col-12">
        Show
        <base-dropdown :title="perPage.toString()" position="center">
          <slot>
            <option
              class="text-center"
              style="cursor: pointer;"
              v-for="(perPg, index) in perPages"
              :key="index"
              :selected="perPg == perPage"
              @click="changePerPage(perPg)"
            >
              {{ perPg }}
            </option>
          </slot>
        </base-dropdown> records <br/>
      </div>
      <div class="col-md-3 col-sm-6 col-12">
        <small>Showing {{this.fro}} to {{this.to}} of {{ this.total }} records</small>
      </div>
      <div class="col mt-sm-3 mt-md-0 mt-3 d-flex justify-content-end">
        <ul
          class="pagination"
          :class="[
            size && `pagination-${size}`,
            align && `justify-content-${align}`,
          ]"
        >
          <li class="page-item prev-page" :class="{ disabled: value === page }">
            <a class="page-link" aria-label="Previous" @click="prevPage">
              <span aria-hidden="true"
                ><i class="fa fa-angle-left" aria-hidden="true"></i
              ></span>
            </a>
          </li>
          <li
            class="page-item"
            :class="{ active: value === item }"
            :key="item"
            v-for="item in range(minPage, maxPage)"
          >
            <a class="page-link" @click="changePage(item)">{{ item }}</a>
          </li>
          <li
            class="page-item next-page"
            :class="{ disabled: value === totalPages }"
          >
            <a class="page-link" aria-label="Next" @click="nextPage">
              <span aria-hidden="true"
                ><i class="fa fa-angle-right" aria-hidden="true"></i
              ></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import BaseDropdown from "./BaseDropdown.vue";
export default {
  components: { BaseDropdown },
  name: "base-pagination",
  props: {
    pageCount: {
      type: Number,
      default: 0,
      description:
        "Pagination page count. This should be specified in combination with perPage",
    },
    page: {
      type: Number,
      default: 1,
      description: "Pagination current page",
    },
    perPage: {
      type: Number,
      default: 10,
      description:
        "Pagination per page. Should be specified with total or pageCount",
    },
    total: {
      type: Number,
      default: 0,
      description:
        "Can be specified instead of pageCount. The page count in this case will be total/perPage",
    },
    value: {
      type: Number,
      default: 1,
      description: "Pagination value",
    },
    size: {
      type: String,
      default: "",
      description: "Pagination size",
    },
    align: {
      type: String,
      default: "",
      description: "Pagination alignment (e.g center|start|end)",
    },
    fro: {
      type: Number,
      default: 0,
      description: "Pagination first record offset",
    },
    to: {
      type: Number,
      default: 0,
      description: "Pagination last record offset",
    },
  },
  computed: {
    totalPages() {
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    perPages() {
      return [5, 10, 25, 50, 100];
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.value - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage() {
      if (this.value >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.value;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    },
  },
  data() {
    return {
      defaultPagesToDisplay: 5,
    };
  },
  methods: {
    range(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePerPage(pg) {
      if(pg != this.perPage)
      this.$emit("change", pg);
    },
    changePage(item) {
      if(item != this.value)
      this.$emit("input", item);
    },
    nextPage() {
      if (this.value < this.totalPages) {
        this.$emit("input", this.value + 1);
      }
    },
    prevPage() {
      if (this.value > 1) {
        this.$emit("input", this.value - 1);
      }
    },
  },
  watch: {
    perPage() {
      this.$emit("input", 1);
    },
    total() {
      this.$emit("input", 1);
    },
  },
};
</script>
