import axios from "axios"
import Jsona from "jsona"

const url = process.env.VUE_APP_API_BASE_URL
const jsona = new Jsona()

function requests(props){
    const options = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    };

    let query = '?page=' + props.page + '&per_page=' + props.perPage

    return axios.get(`${url}/requests${query}`, options)
    .then(response => {
        if (response.data.status == 'OK')
          return response.data.data
        else throw response
     }, error => {
        throw error
      })
}

export default {
    requests
}
