import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import profile from "./modules/profile-module";
import reset from "./modules/reset";
import dashboard from "./modules/dashboard-module";
import account from "./modules/environment-module";
import verify from './modules/verification-module'
import expenditure from './modules/expenditure-module'
import requests from './modules/request-module'
import topups from './modules/topups-module'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    reset,
    dashboard,
    account,
    verify,
    expenditure,
    requests,
    topups,
  }
});
