import service from '@/store/services/profile-service';

const state = {
  me: null,
  trial: null,
  idDocuments: null,
};

const mutations = {
  SET_RESOURCE: (state, me) => {
    state.me = me;
  },
  SET_TRIAL: (state, trial) => {
    state.trial = trial;
  },
  SET_ID_DOCUMENT: (state, docs) => {
    state.idDocuments = docs
  }
};

const actions = {
  me({commit, dispatch}, params) {
    return service.get(params)
      .then((profile) => {
        commit('SET_RESOURCE', profile.list);
      });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((profile) => {
        commit('SET_RESOURCE', profile);
      });
  },
  trial({commit, dispatch}) {
    return service.trial()
      .then((trial) => {
        commit('SET_TRIAL', trial);
      });
  },
  iDocuments({commit, dispatch}, params) {
    return service.iDocuments(params)
    .then((resp)=>{
      return resp
    })
  }
};

const getters = {
  me: state => state.me,
  trial: state => state.trial,
  idDocuments: state => state.idDocuments
};

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default profile;
