import axios from "axios";
import Jsona from "jsona";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function get() {
  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios.get(`${url}/me`, options).then(response => {
    return {
      list: jsona.deserialize(response.data),
      meta: response.data.meta
    };
  });
}

function update(profile) {
  const payload = jsona.serialize({
    stuff: profile,
    includeNames: []
  });

  const options = {
    headers: {
      Accept: "application/vnd.api+json",
      "Content-Type": "application/vnd.api+json"
    }
  };

  return axios
    .patch(`${url}/me`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function trial() {

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  return axios
    .get(`${url}/account/trial_left`, options)
    .then(response => {
      return response.data;
    });
}

function iDocuments(params) {
  
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: params.upProgress,
  };

  return axios
  .post(`${url}/account/veriff`, params.data, options)
  .then(response => {
    console.log(response)
    if(response.data.status == 'OK') {
      return response.data;
    }
    throw response.data
  })
}

export default {
  get,
  update,
  trial,
  iDocuments
};
