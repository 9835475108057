import axios from 'axios'
import Jsona from 'jsona'
import CryptoJS from "crypto-js";

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function verify(data) {

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-request-api-key": data.key
    },
  };

  return axios.post(`${url}/verify`, data.payload, options)
    .then(response => {
      return response.data;
    }, error => {
      throw error
    })
}

function batchVerify(data) {

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-request-api-key": data.key
    },
  };

  return axios.post(`${url}/verify/batch`, data.payload, options)
    .then(response => {
      if (response.data.status == 'OK')
        return response.data;
      else throw response
    }, error => {
      throw error
    })
}

export default {
  verify,
  batchVerify
}
