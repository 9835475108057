import service from '@/store/services/dashboard-service';

const state = {
    counters: {
        total: 0,
        successful: 0,
        failed: 0,
        performance: 0,
    },
    sinceLastMonth: {
        total: null,
        successful: null,
        failed: null,
    },
    balance: {
        balance: 0,
        requests: 0,
    },
    lineChart: {
        data: [],
        labels: []
    },
    barChart: {
        data: [],
        labels: []
    }
}

const mutations = {
    SET_COUNTERS: (state, counters) => {
        state.counters = counters
    },
    SET_LINE_CHART: (state, lineChart) => {
        state.lineChart = lineChart;
    },
    SET_BAR_CHART: (state, barChart) => {
        state.barChart = barChart;
    },
    SET_BALANCE: (state, balance) => {
        state.balance = balance;
    },
    SET_REQUESTS: (state, balance) => {
        state.balance = balance;
    },
    SET_SINCE_L_M: (state, balance) => {
        state.sinceLastMonth = balance;
    },
}

const actions = {
    index({ commit, dispatch }, params) {
        return service.index(params)
            .then((response) => {
                console.log("module", response.data.counters.since_last_month)
                commit('SET_COUNTERS', {
                    total: response.data.counters.total,
                    successful: response.data.counters.successful,
                    failed: response.data.counters.failed,
                    performance: (response.data.counters.successful / response.data.counters.total) * 100,
                });
                commit('SET_SINCE_L_M', response.data.counters.since_last_month)
                commit('SET_LINE_CHART', response.data.charts.line);
                commit('SET_BAR_CHART', response.data.charts.bar);
            })
    },
    update_counters({ commit, dispatch }, params) {
        return service.update_counters(params)
            .then((response) => {
                console.log("module", response)
                commit('SET_COUNTERS', {
                    total: response.data.total,
                    successful: response.data.successful,
                    failed: response.data.failed,
                    performance: response.data.total != 0 ? (response.data.successful / response.data.total) * 100 : "N/A",
                });
                commit('SET_SINCE_L_M', response.data.since_last_month)
            })
    },
    update_charts({ commit, dispatch }, params) {
        return service.update_charts(params)
            .then((response) => {
                commit('SET_LINE_CHART', response.data.line);
                commit('SET_BAR_CHART', response.data.bar);
            })
    },
    balance({ commit, dispatch }, params) {
        return service.balance(params)
            .then((response) => {
                commit('SET_BALANCE', response.data);
            })
    }
}

const getters = {
    counters: state => state.counters,
    sinceLastMonth: state => state.sinceLastMonth,
    lineChart: state => state.lineChart,
    barChart: state => state.barChart,
    balance: state => state.balance.balance,
    requests: state => state.balance.requests
}

const dashboard = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default dashboard
