import { getToken } from 'firebase/messaging'

const requestPermission = () => {
    console.log('Requesting notification persmission ...')
    Notification.requestPermission().then((permission) => {
        if(permission === 'granted') {
            console.log('Notification permissions granted.')
            console.log('Proceeding ...')
        }
    })
}

const getFCMToken = (messaging) => {
    getToken(messaging, { 
        vapidKey: "BDJNK1fevFw9e8rKnn-SkMXFH7rYVAjtLKusxElgXpezSbkfqre5ABq9ohs3FCJ7GtW_QuD2Y38SEUiukY5OO3A"
      }).then((currentToken) => {
        if(currentToken) {
            console.log(currentToken)
          // Send token to server and update UI
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.')
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
      })
}

export {
    requestPermission,
    getFCMToken,
}
