<template>
  <div class="main-content bg-gradient-info">
    <base-nav
      class="navbar-sticky-top navbar-horizontal navbar-dark fixed-top"
      containerClasses="container-fluid w-full px-1 py-2"
      expand
      style="padding: unset; background-color: #06e1ec !important"
    >
      <router-link slot="brand" class="navbar-brand pt-3" to="/">
        <img
          src="/img/brand/white.png"
          width="140"
          height="60"
          style="height: unset;"
        />
      </router-link>

      <span slot="account_balance"></span>

      <template v-slot="{ closeMenu }">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img src="/img/brand/green.png" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                @click="closeMenu"
                class="navbar-toggler"
                aria-label="Toggle sidenav"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <ul class="navbar-nav ml-auto">
          <!-- <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/login">
              <span class="nav-link-inner--text">Login</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/register">
              <span class="nav-link-inner--text">Register</span>
            </router-link>
          </li> -->
        </ul>
        <hr class="d-lg-none" />
      </template>
    </base-nav>
    <!-- Header -->
    <div class="header bg-gradient-info pt-7 pt-lg-8 pt-lg-9 pb-0">
      <div class="container">
        <div class="header-body text-center mb">
          <div class="text-center" style="margin-bottom: 5px">
            <div v-if="isLogin"></div>
            <div v-if="isRegister"></div>
            <div v-if="isPassword"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
    <!-- Page Content -->
    <div class="pb-5 px-4">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
    <footer class="py-4 fixed-bottom position-absolute" id="footer-main">
      <div class="container">
        <div class="row align-items-center justify-content-xl-between">
          <div
            class="offset-3 text-center col-xl-6 pr-1"
            style="display: flex; justify-content: center"
          >
            <div
              class="copyright text-center text-muted bg-info"
              style="width: fit-content; padding: 0 5px; border-radius: 6px"
            >
              © {{ year }}
              <a
                href="#"
                class="font-weight-bold ml-1"
                target="_blank"
                rel="noopener"
                >Veritas interactive</a
              >
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import BaseNav from "../components/BaseNav.vue";
import { SlideYUpTransition } from "vue2-transitions";

export default {
  components: { BaseNav, SlideYUpTransition },
  name: "docs-layout",
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  computed: {
    isLogin() {
      return this.$route.name === "login";
    },
    isRegister() {
      return this.$route.name === "register";
    },
    isPassword() {
      return this.$route.name === "PasswordReset";
    },
  },
};
</script>
