import service from '@/store/services/topup-service';

const state = {
    data: [],
    paymentMethods: null
}

const mutations = {
    SET_TOPUPS: (state, topups) => {
        state.data = topups
    },
    SET_PAYMENT_METHODS: (state, paymentMethods) => {
        state.paymentMethods = paymentMethods.data
    }
}

const actions = {
    topups({ commit, dispatch }, params) {
        return service.topups(params)
            .then((topups) => {
                commit('SET_TOPUPS', topups.data);
            })
    },
    getPaymentMethods({ commit, dispatch }, params) {
        return service.getPaymentMethods(params)
            .then((paymentMethods) => {
                commit('SET_PAYMENT_METHODS', paymentMethods);
            })
    }
}

const getters = {
    topupsTable: state => state.data,
    paymentMethods: state => state.paymentMethods
}

const topups = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default topups
