const INACTIVE_USER_TIME_THRESHOLD = 150000;
const USER_ACTIVITY_THROTTLER_TIME = 150000;
export default {
  data() {
    return {
      isInactive: false,
      userActivityTimeout: null,
      userActivityThrottlerTimeout: null,
      alertTimeout: 10000,
    }
  },
  computed: {
    USER_ACTIVITY_THROTTLER_TIME() {
      return parseInt(process.env.VUE_APP_INACTIVITY_TIMEOUT || 10000);
    }
  },
  methods: {
    activateActivityTracker() {
      console.log('Tracker activated')
      window.addEventListener("mousemove", this.userActivityThrottler);
      window.addEventListener("scroll", this.userActivityThrottler);
      window.addEventListener("keydown", this.userActivityThrottler);
      window.addEventListener("resize", this.userActivityThrottler);
    },

    deactivateActivityTracker() {
      console.log('Tracker deactivated')
      window.removeEventListener("mousemove", this.userActivityThrottler);
      window.removeEventListener("scroll", this.userActivityThrottler);
      window.removeEventListener("keydown", this.userActivityThrottler);
      window.removeEventListener("resize", this.userActivityThrottler);
    },

    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);
      this.isInactive = true;

      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction()
      }, INACTIVE_USER_TIME_THRESHOLD);
    },

    userActivityThrottler() {
      if (this.isInactive) {
        this.isInactive = false;
      }

      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();

          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, USER_ACTIVITY_THROTTLER_TIME);
      }
    },
    inactiveUserAction() {
      try {
        if (this.$store.state.auth.isAuthenticated) {
          this.$store.dispatch("logout");
          
        } else {
          this.deactivateActivityTracker()
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  }
}
