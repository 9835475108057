<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <modal
      :show="this.isInactive"
      :showClose="true"
      type="notice"
      gradient="default"
      headerClasses="justify-content-center"
      :modalClose="() => {}"
    >
      <div
        slot="header"
        class="d-flex flex-row justify-content-center text-white"
      >
        <i class="fa fa-exclamation mr-4 display-3"></i>
        <div
          class="d-flex flex-col justify-content-center align-content-center flex-wrap text-center"
        >
          <div class="display-3">Session expiring in 5 minutes</div>
          <small class="text-muted">
            Due to user inactivity, the session will close in 5 minutes.
          </small>
        </div>
      </div>
    </modal>
    <side-bar
      :background-color="sidebarBackground"
      short-title="NIRA Kyc"
      title="NIRA KYC"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Requests',
            icon: 'ni ni-send text-success',
            path: '/requests',
          }"
        />

        <!-- <sidebar-item
          :link="{
            name: 'Transactions',
            icon: 'ni ni-sound-wave text-danger',
            path: '/transactions',
          }"
        /> -->

        <sidebar-item
          :link="{
            name: 'Reports (In Beta)',
            icon: 'ni ni-chart-bar-32 text-info',
            path: '/reports',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Billings',
            icon: 'fa fa-money-bill',
            path: '/billings/statements/topups',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Payment Methods',
              path: '/billings/payments/methods',
            }"
          />
          <sidebar-item
            :link="{ name: 'Top Ups', path: '/billings/statements/topups' }"
          />
          <sidebar-item
            :link="{
              name: 'Expenditure',
              path: '/billings/statements/expenditure',
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Settings',
            icon: 'ni ni-settings-gear-65 text-warning',
          }"
        >
          <sidebar-item :link="{ name: 'Keys', path: '/settings/keys' }" />
          <!-- <sidebar-item :link="{ name: 'Docs', icon: 'fa fa-book', path: '//documenter.getpostman.com/view/11063106/UVeGpkdV#3e684207-8b09-4f1e-8d1e-5f2b441a1cab', target:'_blank' }" /> -->

          <!-- <sidebar-item
            :link="{ name: 'Switch to',  switch: true, isRoute: true }"
          /> -->
        </sidebar-item>
          <li class="nav-item">
            <a
              href="javascript:void(0)"
              class="nav-link"
              @input="verified ? () => {} : toggleEnvironment"
            >
              Switch to {{ demoEnv ? "Live" : "Demo" }}
              <div style="margin-left: 20px"></div>
              <a href="#switch_env" @click="this.toggleEnvironment">
                <base-switch
                  :checked="this.env"
                  :value="this.env"
                  :disabled="!this.verified"
                />
              </a>
            </a>
          </li>

        <!-- <sidebar-item :link="{
        //     name: 'Icons',
        //     icon: 'ni ni-planet text-blue',
        //     path: '/icons',
        //   }"
        // />
        // <sidebar-item
        //   :link="{
        //     name: 'Maps',
        //     icon: 'ni ni-pin-3 text-orange',
        //     path: '/maps',
        //   }"
        // />
        // <sidebar-item
        //   :link="{
        //     name: 'User Profile',
        //     icon: 'ni ni-single-02 text-yellow',
        //     path: '/profile',
        //   }"
        // />
        // <hr
        //   class="my-3"
        //   style="
        //     border: 0;
        //     border-top: 1px solid rgba(0, 0, 0, 0.1);
        //     min-width: 80%;
        //     overflow: visible;
        //     box-sizing: content-box;
        //     height: 0;
        //   "
        // />
        -->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
import inactivityMixin from "../mixins/inactivity-mixin";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    Modal,
  },
  mixins: [inactivityMixin],
  beforeMount() {
    this.activateActivityTracker();
  },
  beforeDestroy() {
    this.deactivateActivityTracker();

    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      demoEnv: true,
      envChange: false,
    };
  },
  computed: {
    env() {
      return !this.demoEnv;
    },
    verified() {
      return this.$store.state.profile.me?.verified ?? false;
    },
  },
  created() {
    this.$store.watch(
      () => this.$store.getters["account/isDemo"],
      (me) => {
        this.demoEnv = me;
      }
    );
  },
  async mounted() {
    await this.$store.dispatch("account/getEnv");
    this.demoEnv = this.$store.state.account.isDemo;
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    async toggleEnvironment() {
      if (!this.envChange && this.verified) {
        this.envChange = true;
        try {
          await this.$store.dispatch("account/change", !this.demoEnv);

          if (this.demoEnv)
            this.$notify({
              type: "success",
              message: "Change to Live environment successful.",
            });
          else
            this.$notify({
              type: "success",
              message: "Change to Demo environment successful.",
            });

          await this.$store.dispatch("profile/me");
        } catch (error) {
          console.log(error);
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
            // message: error
          });
        }

        this.demoEnv = this.$store.state.account.isDemo;
        this.envChange = false;
      } else {
        if (!this.envChange) {
          this.$notify({
            type: "warning",
            message:
              "Upload and have the identification data verified, before changing to live",
          });
          this.demoEnv = this.$store.state.account.isDemo;
        }
      }
    },
  },
};
</script>
<style lang="scss"></style>
